.top-navbar {
  min-height: 50px;
  max-height: 50px;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 16px;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.11);
}
/* 
.top-navbar a:hover {
  background-color: rgba(0, 0, 0, 0.1);
} */
.menu-section {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: none !important;
  border-radius: 0 !important;
  min-width: 175px;
  margin-right: 50px;
}
.text-gray {
  color: var(--gray-400);
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: rgb(56 160 219 / var(--tw-text-opacity)) !important;
  color: var(--primary-600) !important;
  background: var(--gray-100) !important;
}
