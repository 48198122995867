body {
  font-family: 'Roboto', 'Poppins' !important;
  padding: 65px 0 20px;
}
.font-primary {
  font-family: 'Roboto', 'Poppins' !important;
}
/* .templates h1 {
  color: #155eef;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.5rem;
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  margin: 2rem 0 1.75rem;
} */

.templates h3 {
  text-align: center;
  color: #666;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
  margin-top: 40px;
}

.video-tutorial {
  color: #155eef;
  text-align: center;
}

.video-tutorial a {
  margin-left: 5px;
  cursor: pointer;
}

.video-tutorial a:hover {
  text-decoration: underline !important;
}
.border-line {
  margin: 20px 0 50px;
  border-top: solid 1px #eee;
}
.featured-btns {
  transition: all 0.3s;
  display: block;
  padding: 8px 0;
  border-radius: 8px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
  text-align: center;
  cursor: pointer;
  user-select: none;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-200) !important;
  background-color: var(--base-white) !important;
}
.active-featured-btns {
  background-color: var(--primary-600) !important;
  border: 1px solid var(--primary-600) !important;
  color: var(--base-white) !important;
  /* box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important; */
}
.featured-btns:hover {
  background-color: var(--primary-600) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: var(--base-white) !important;
  border: 1px solid var(--primary-600) !important;
}
.smart-image-container {
  transition: all 0.3s;
  /* color: #999; */
  display: inline-block;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
  text-align: center;
  vertical-align: middle;
  height: 180px;
  overflow: hidden;
  line-height: 180px;
  margin: 21px;
  cursor: pointer;
}
.smart-image-container:hover {
  /* box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
  outline: none;
}
.innerImg {
  vertical-align: middle;
  width: 100%;
}

.smart-image-container-wrap:hover {
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important; */
  outline: none;
}
