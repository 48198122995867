p,
a {
  font-size: 0.875rem !important;
}
/* general */
.rounded-1 {
  border-radius: 0.4rem !important;
}
/* buttons */
.btn {
  padding: 0 1.25rem !important;
  text-transform: none !important;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: var(--base-white);
  background-color: var(--primary-600);
  border-color: var(--primary-600);
}

.btn-primary:hover {
  color: var(--base-white);
  background-color: var(--primary-700);
  border-color: var(--primary-700);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: var(--base-white);
  background-color: var(--primary-600);
  border-color: var(--primary-600);
  box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.05);
}

.btn-rounded-circle {
  border-radius: 8px !important;
  padding: 0px !important;
  max-width: 40px !important;
  max-height: 40px !important;
  width: 35px !important;
  height: 35px !important;
  line-height: 35px;
}
/* end of buttons */
.toolbar-container {
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
  outline: none;
}
.templates .card.shadow-sm-hover {
  box-shadow: 0 0.525rem 1.75rem rgba(0, 0, 0, 0.08) !important;
}

.rounded-top {
  border-radius: 8px 8px 0px 0px !important;
  border: 1px solid var(--primary-600) !important;
}

.toolbar-container .toolbar-title {
  background-color: #267ed5;
  color: #e2eff7;
  line-height: 46px;
  font-size: 16px;
  padding: 0 5px 0 10px;
}

.toolbar-container .toolbar-content {
  /* padding: 10px 20px;
  background-color: #fff;
  min-height: 200px;
  margin-bottom: 30px; */
  outline: none;
}

.toolbar-content div {
  padding: 16px 11px;
  cursor: pointer;
}
.toolbar-content1{
  padding: 20px;
  cursor: pointer;
}
.preview-btn {
  border: none;
  /* padding: 4px 7px; */
  border-radius: 6px;
  background: var(--primary-600);
  /* color: #fff; */
  margin-top: 9px;
}

.text-xs {
  font-size: 0.75em !important;
}
.bg-primary {
  background-color: var(--primary-600) !important;
}
.bg-primary-tint {
  background-color: var(--primary-25) !important;
}

.editimgcards .card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editimgcards .card-content {
  flex: 1 1 auto;
}

.editimgcards .card-footer {
  flex-shrink: 0;
}
