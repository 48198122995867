.imageDetails .nav-link {
  background: var(--gray-100) !important;
  height: 48px !important;
  width: 200px !important;
  transition: 0.3s ease-in !important;
  -webkit-border-top-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  -webkit-border-top-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
  text-transform: none !important;
  border: solid 1px #e9e9e9 !important;
  transition: 1s all ease !important;
}

.imageDetails .nav-link.active {
  background: var(--gray-25) !important;
  color: #000 !important;
  border-bottom: 2px solid var(--primary-600) !important;
  position: relative;
  /* top: -3px; */
  /* z-index: 99; */
  /* border-bottom: 0px solid #fff !important; */
  border-radius: 8px 8px 0px 0px;
  transition: 1s all ease !important;
}

.url-part {
  color: #d14;
}
.src-part {
  color: var(--primary-600);
}
.query-part {
  color: green;
}
.code-container {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-family: monospace;
  white-space: pre-wrap;
  font-size: 1.25rem;
}
code {
  color: #999 !important;
  cursor: auto !important;
}
.imageVar {
  border: solid 1px var(--gray-100);
  border-radius: 4px;
}
.imageVar .card-header {
  border-bottom: solid 1px var(--gray-100);
  text-align: left;
  font-size: 17px;
  padding: 5px 0;
  color: var(--primary-600);
  background-color: #f8f8f8;
}
.imageVar input {
  border: 1px solid #9e9e9e;
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  outline: none !important;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9e9e9e;
  padding: 10px 10px;
}

.imagePreview {
  /* box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  width: 100%;
}
.name {
  color: var(--primary-600);
  /* font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding-bottom: 10px;
  margin-left: 20px; */
}
.firstBox {
  background-color: #ff6f00;
  height: 75px;
}
.secondBox {
  background-color: #ffa000;
  height: 75px;
  color: #fff;
  padding-right: 10px;
}
.openCount {
  font-size: 1.64rem;
  line-height: 110%;
  padding-top: 10px;
}

.nav.nav-pills {
  position: relative;
  top: 4px;
}

.tab-content {
  /* min-height: calc(100vh - 220px); */
  outline: none;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08) !important;
}

.card {
  border-color: #e9e9e9 !important;
}

#pills-tabContent {
  /* border-left: 0px !important;
  border-right: 0px !important; */
  /* box-shadow: none !important; */
  outline: none;
}

.bg-gray-100 {
  background: var(--gray-100) !important;
}
.btn-default {
  background-color: var(--base-white) !important;
  color: var(--gray-900) !important;
  border: 1px solid var(--gray-300) !important;
}
.pad-sm {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.btn-default.active,
.btn-default:hover {
  background-color: var(--primary-600) !important;
  color: var(--base-white) !important;
  border: 1px solid var(--primary-600) !important;
}

.bg-gray-200 {
  background-color: var(--gray-100) !important;
  color: var(--gray-900) !important;
  /* border: 1px solid var(--gray-300) !important; */
}

.shadow-md-new {
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06) !important;
}

.card.shadow-sm:hover {
  box-shadow: 0 0.525rem 0.5rem rgba(0, 0, 0, 0.04) !important;
  outline: none;
}
