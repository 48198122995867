@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://kit.fontawesome.com/5e5db45630.css);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;600;700&display=swap');
@import url('https://app.smartyimages.com/api/stylesheets/fonts.css');
:root {
  --primary-bg-body: #f2f7fa;
  --secondary-bg-body: #f2f7fa;
  --base-white: #ffffff;
  --base-black: #000000;
  --primary-25: #f5f8ff;
  --primary-50: #eff4ff;
  --primary-100: #d1e0ff;
  --primary-200: #b2ccff;
  --primary-300: #84adff;
  --primary-400: #528bff;
  --primary-500: #2970ff;
  --primary-600: #155eef;
  --primary-700: #004eeb;
  --primary-800: #0040c1;
  --primary-900: #00359e;
  --success-25: #f6fef9;
  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-200: #a6f4c5;
  --success-300: #6ce9a6;
  --success-400: #32d583;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;
  --warning-25: #fffcf5;
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;
  --warning-800: #93370d;
  --warning-900: #7a2e0e;
  --error-25: #fffbfa;
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-200: #ffcdca;
  --error-300: #fda29b;
  --error-400: #fa7066;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;
  --error-800: #912018;
  --error-900: #7a271a;
  --gray-25: #fcfdfd;
  --gray-50: #f9fafb;
  --gray-100: #f2f4f7;
  --gray-200: #eaecf0;
  --gray-300: #d0d5dd;
  --gray-400: #98a2b3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #1d2939;
  --gray-900: #101828;
  --box-shadow: 0 0 0 2px rgba(21, 94, 239, 0.2);
  --shadow-md: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  --shadow-lg: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  --base-black-rgba-opacity: rgba(0, 0, 0, 0.5);
  --tw-text-opacity: 1;
}

body {
  background-color: var(--primary-bg-body);
  font-family: 'Roboto', 'Poppins' !important;
}

body {
  /* padding: 65px 0 20px; */
  /* padding: 20px 0 20px; */
  outline: none;
}

*::-webkit-scrollbar {
  width: thin;
}

/* WebKit browsers */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--gray-50);
}

*::-webkit-scrollbar-thumb {
  background: var(--gray-100);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background: var(--gray-100);
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--gray-100) var(--gray-25) !important;
}

/* Edge */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.container-main {
  /* max-width: 1480px; */
  max-width: 1680px;
  margin: 0px auto;
  height: calc(100vh - 100px);
  width: 100%;
}

.new-button {
  display: block;
  text-align: center;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 15px 5px 10px;
  transition: all 0.3s ease-out;
  margin: 20px auto;
  color: var(--primary-600);
  cursor: pointer;
  max-width: 30%;
}

.sc-aXZVg {
  width: 100%;
}

.new-button:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.new-button i {
  display: block;
  margin: 0 auto;
  font-size: 50px;
}

.new-button span {
  line-height: 44px;
  font-size: 20px;
}

.material-icons {
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
}

.studio-start-container {
  min-height: 230px;
}

.studio-start-container a.btn {
  text-transform: none;
}

.studio-start-container .drop-box {
  margin: 0;
  margin-bottom: 20px;
  border-color: #ddd;
}

.studio-start-container .drop-box h4 {
  color: #ccc;
}

.studio-start-container .drop-box.progressing {
  border-color: var(--primary-600);
}

.studio-start-container .drop-box.progressing h4 {
  color: var(--primary-600);
}

.studio-start-container .drop-box.file-hover {
  border-color: green;
  color: #000;
}

.studio-start-container .drop-box.file-hover h4 {
  color: #000;
}

.drop-box,
.modal .modal-content .drop-box {
  border: dashed 0px #ccc;
  color: #999;
  margin: 30px 10px;
  padding: 30px;
  text-align: center;
  border-radius: 8px;
}

.drop-box h4,
.modal .modal-content .drop-box h4 {
  font-size: 2.25rem;
  line-height: 2.25rem;
  color: #ccc;
  margin: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.drop-box h4 i,
.modal .modal-content .drop-box h4 i {
  float: none !important;
  vertical-align: middle;
  font-size: inherit;
  margin-right: 0;
}

.drop-box h4.progress-title,
.modal .modal-content .drop-box h4.progress-title {
  display: none;
  color: var(--primary-600);
}

.drop-box.file-hover,
.modal .modal-content .drop-box.file-hover {
  border-color: green;
  background-color: #abe18e;
  color: #333;
}

.drop-box.file-hover h4,
.modal .modal-content .drop-box.file-hover h4 {
  color: #000;
}

.drop-box.progressing,
.modal .modal-content .drop-box.progressing {
  border-color: var(--primary-600);
}

.drop-box.progressing h4,
.modal .modal-content .drop-box.progressing h4 {
  display: none;
}

.drop-box.progressing h4.progress-title,
.modal .modal-content .drop-box.progressing h4.progress-title {
  display: block;
}

.btn,
.btn-large {
  text-decoration: none;
  color: #fff;
  background-color: var(--primary-600);
  text-align: center;
  letter-spacing: 0.5px;
  transition: 0.2s ease-out;
  cursor: pointer;
}

.btn,
.btn-large,
.btn-flat {
  border: none;
  border-radius: 5px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  outline: 0;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.image-thumb {
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.image-thumb:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image-link {
  text-decoration: none;
  color: var(--primary-600);
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid var(--primary-600);
  border-radius: 5px;
  margin: 5px;
  display: inline-block;
}

.image-editor-container {
  /* width: calc(100vw - 180px) !important; */
  height: calc(100vh - 100px) !important;
}
.bNOygi,
.fnGtpT span {
  font-family: system-ui;
  font-weight: 600;
}

.hl-display-sm-medium {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
}

.topmenu-navtitle {
  text-align: left !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: 500 !important;
}
#upload-wrapper label {
  border: 2px dashed var(--gray-200) !important;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}
.templates {
  padding: 20px 30px;
}

#cutom-menu-link,
#cutom-menu-link iframe {
  height: calc(100vh - 50px) !important;
}

.text-notice {
  color: #f79009;
}
/* ends here */
#top-navbar .nav-link {
  color: var(--gray-900);
  border-bottom: 2px solid var(--base-white);
  padding: 16px 0px;
  margin: 0 10px;
}

#top-navbar .nav-link.active,
#top-navbar .nav-link:hover {
  border-bottom: 2px solid var(--primary-400);
  color: var(--primary-400);
}

/* image editor css */

.image-editor-container-new *:not(.FIE_annotation-option-triggerer *) {
  font-size: 13px;
}
.image-editor-container-new {
  height: calc(100vh - 90px) !important;
  width: calc(100vw - 275px) !important;
  margin: auto !important;
  position: relative !important;
  left: 128px !important;
  margin-top: 0px !important;
}

button.SfxButton-root {
  background-color: var(--primary-600) !important;
  color: var(--base-white) !important;
}
button.SfxButton-root svg {
  color: var(--base-white) !important;
}
.FIE_topbar-save-button {
  background-color: var(--primary-600) !important;
  /* position: fixed !important; */
  bottom: 10px !important;
  left: 10px !important;
  z-index: 10 !important;
  width: 100px !important;
  padding: 10px 0px !important;
}

/* .FIE_topbar-save-button::after {
  content: 'Changes';
  position: absolute;
  top: 0;
  right: 0;
} */
.smart-images-topbar {
  width: 500px !important;
}

.smart-images-tools {
  position: fixed !important;
  left: 0 !important;
  top: 50px !important;
  max-width: 250px !important;
  min-height: calc(100vh - 50px) !important;
  border: 0px solid var(--gray-100) !important;
  z-index: 9 !important;
  background-color: var(--base-white) !important;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.02) !important;
  padding-top: 200px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.FIE_tabs {
  box-shadow: none !important;
  outline: none !important;
  width: 0px !important;
  height: 100vh !important;
}

.FIE_tools-bar {
  margin-top: -280px !important;
  padding: 10px !important;
}
.FIE_tools-wrapper {
  /* margin-bottom: 150px !important;
  padding-bottom: 150px !important; */
  outline: none;
}
.bQGxcZ {
  gap: 12px !important;
  display: flex !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.FIE_annotation-option-triggerer {
  padding: 10px 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-radius: 5px !important;
  border: 1px solid var(--gray-100) !important;
  box-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.075) !important;
}

.FIE_annotation-option-triggerer:hover {
  background-color: var(--gray-100) !important;
  border: 1px solid var(--gray-200) !important;
}

.FIE_text-tool-button[aria-selected='true'],
.FIE_text-tool-button:hover,
.FIE_image-tool-button[aria-selected='true'],
.FIE_image-tool-button:hover,
.FIE_rect-tool-button[aria-selected='true'],
.FIE_rect-tool-button:hover {
  background-color: var(--gray-100) !important;
}

.FIE_text-tool-options,
.FIE_tools-items,
.FIE_annotations-options.FIE_image-tool-options,
.FIE_annotations-options.FIE_rect-tool-options {
  border: 1px solid #f0f0f0 !important;
  padding: 15px !important;
  margin: 5px 10px !important;
  margin-bottom: 10px !important;
  border-radius: 5px !important;
  box-shadow: 0 0.05rem 0.5rem rgba(0, 0, 0, 0.07) !important;
}
.FIE_tools-items {
  margin: 15px 0px !important;
  border: 1px solid #f0f0f0 !important;
  padding: 10px !important;
  border-radius: 5px !important;
  box-shadow: 0 0.05rem 0.5rem rgba(0, 0, 0, 0.07) !important;
}
.FIE_text-tool-options:hover {
  outline: none;
}
.FIE_text-size-option,
.FIE_text-font-family-option {
  margin-left: 0px !important;
  position: relative !important;
  width: 190px !important;
  border: 1px solid var(--gray-200) !important;
}
.FIE_text-size-option input::placeholder {
  outline: none;
  color: var(--gray-900) !important;
}
.FIE_annotations-options.FIE_text-tool-options {
  justify-content: start !important;
}
.FIE_annotations-options.FIE_image-tool-options .FIE_image-tool-add-option-button {
  width: 200px !important;
}
.FIE_image-tool-add-option-wrapper {
  margin: 0 !important;
}

.FIE_text-bold-option,
.FIE_text-italic-option {
  margin: 4px !important;
}

.FIE_text-bold-option:hover,
.FIE_text-italic-option:hover {
  background-color: var(--gray-100) !important;
}
.FIE_tools-item-wrapper.FIE_carousel-item {
  display: flex !important;
}

.gCYGTt,
.jBlYyL,
.FIE_annotation-option-popup > div {
  min-width: 160px;
  width: 195px;
  min-height: 16px;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0, 1), transform 250ms cubic-bezier(0.4, 0, 0, 1), margin-top 250ms cubic-bezier(0.4, 0, 0, 1);
  outline: 0;
  box-shadow: 0 2px 4px var(--gray-200) !important;
  border-radius: 4px;
  scrollbar-color: var(--gray-200) #fff !important;
  scrollbar-width: thin !important;
  max-height: 350px !important;
  margin-top: 2px;
  border: 1px solid var(--gray-200) !important;
  font-size: 13px !important;
  animation: jBcSpD 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1), hobtDz 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.FIE_annotation-option-popup {
  margin-left: 10px !important;
}

.FIE_annotation-option-popup > div {
  padding: 20px !important;
  width: unset !important;
  font-size: 13px !important;
  padding-bottom: 5px !important;
  min-height: 90px !important;
  padding-top: 32px !important;
  padding-bottom: 25px !important;
}
.SfxLabel-text {
  font-size: 12px !important;
}
.jBlYyL {
  margin-left: 10px !important;
  text-align: left !important;
}
.gkipMK {
  background-color: var(--gray-100) !important;
  outline: none;
}
.giDnZA {
  justify-content: start !important;
}

.gmWLXJ .FIE_text-font-family-item:hover,
.giDnZA:hover,
.BhJTG :hover,
.BhJTG {
  background-color: var(--gray-100) !important;
}
.gkfINL {
  background-color: var(--gray-100) !important;
}

.byITrQ .SfxSlider-thumb,
.bGamnD .SfxSlider-thumb,
.SfxSlider-thumb {
  background-color: var(--primary-600) !important;
}
.SfxSlider-thumb::before {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

button.SfxButton-root {
  margin: 3px !important;
}
.FIE_annotation-controls-overlay {
  height: 40px !important;
  background-color: var(--base-black) !important;
  background: transparent !important;
}

.gRPuSI {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.FIE_editor-content .SfxButton-root,
button.SfxButton-root.jbKqnj,
.FIE_topbar-reset-button.kZfAKc,
.FIE_topbar-undo-button.kZfAKc,
.FIE_topbar-redo-button.gjtEeK,
.FIE_topbar-close-button.fDClKz {
  background-color: var(--gray-100) !important;
  color: var(--base-black) !important;
  border: 1px solid var(--gray-200) !important;
}

.FIE_editor-content .SfxButton-root[disabled='true'],
button.SfxButton-root.jbKqnj[disabled='true'],
.FIE_topbar-reset-button.kZfAKc[disabled='true'],
.FIE_topbar-undo-button.kZfAKc[disabled='true'],
.FIE_topbar-redo-button.gjtEeK[disabled='true'],
body #root button.SfxButton-root[disabled='true'] {
  background-color: var(--gray-100) !important;
  color: var(--base-black) !important;
  border: 1px solid var(--gray-200) !important;
}
.FIE_topbar-history-buttons button {
  background-color: var(--gray-100) !important;
  color: var(--base-black) !important;
  border: 1px solid var(--gray-200) !important;
}
.FIE_topbar-history-buttons button svg {
  color: var(--base-black) !important;
  width: 16px !important;
  height: 16px !important;
}
.FIE_editor-content .SfxButton-root svg,
button.SfxButton-root.jbKqnj svg,
.FIE_topbar-reset-button.kZfAKc svg,
.FIE_topbar-undo-button.kZfAKc svg,
.FIE_topbar-redo-button.gjtEeK svg,
.FIE_topbar-close-button.fDClKz svg {
  color: var(--base-black) !important;
  width: 16px !important;
  height: 16px !important;
}

.FIE_editor-content .SfxButton-root:hover,
button.SfxButton-root.jbKqnj:hover,
.FIE_topbar-reset-button.kZfAKc:hover,
.FIE_topbar-undo-button.kZfAKc:hover,
.FIE_topbar-redo-button.gjtEeK:hover,
.FIE_topbar-close-button.fDClKz:hover {
  background-color: var(--primary-600) !important;
  color: var(--base-white) !important;
  border: 1px solid var(--primary-800) !important;
}
.FIE_editor-content .SfxButton-root:hover svg,
button.SfxButton-root.jbKqnj:hover svg,
.FIE_topbar-reset-button.kZfAKc:hover svg,
.FIE_topbar-undo-button.kZfAKc:hover svg,
.FIE_topbar-redo-button.gjtEeK:hover svg,
.FIE_topbar-close-button.fDClKz:hover svg {
  color: var(--base-white) !important;
}

.FIE_text-tool-options .FIE_color-picker-triggerer,
.FIE_rect-tool-options .FIE_color-picker-triggerer {
  width: 22px !important;
  height: 22px !important;
  border: var(--base-white) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
  margin-left: auto !important;
  margin-right: 8px !important;
}
.FIE_color-picker-triggerer {
  width: 22px !important;
  height: 22px !important;
  border: var(--base-white) !important;
}
.FIE_text-tool-options .FIE_color-picker-triggerer::after,
.FIE_rect-tool-options .FIE_color-picker-triggerer::after {
  color: var(--gray-600) !important;
  content: 'Choose Color';
  position: absolute;
  left: 28px;
}
.giDnZA > div > svg ~ span {
  display: inline-block !important;
  width: 30px !important;
}
.imageVar input {
  width: 100% !important;
  border: 1px solid var(--gray-200) !important;
  border-radius: 5px !important;
  padding: 8px 15px !important;
  color: var(--gray-500) !important;
}

.imageVar.card.shadow .p-2 {
  padding: 20px !important;
}
.imageVar.card.shadow .p-2 label.wrapper {
  padding: 5px 1px !important;
  text-transform: capitalize !important;
}
.bg-primary-tint {
  background-color: var(--primary-bg-body) !important;
}
/* end of image editor */
/* spinner */

.spinner-wrapper {
  min-height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-border,
.spinner-border-small {
  color: var(--cp-sup-primary-col);
  width: 2rem;
  height: 2rem;
}

.spinner-border::before {
  border-color: var(--cp-sup-primary-col) transparent transparent !important;
}

.spinner-border-small {
  color: var(--sup-primary-tinthard_col);
  width: 1rem;
  height: 1rem;
  opacity: 0.8;
}
.spinner-border-small-xs {
  color: var(--sup-primary-tinthard_col);
  width: 0.7rem;
  height: 0.7rem;
  opacity: 1;
}
.jgVgZG.SfxButton-root,
.jdyUHg.SfxButton-root {
  border-radius: 8px;
}
.loYrfs.FIE_resize-ratio-locker {
  background: var(--gray-200) !important;
}
.FIE_text-italic-option,
.FIE_text-bold-option {
  width: 24px !important;
  height: 24px !important;
}
.FIE_root .SfxPopper-root .SfxMenu-root, 
#SfxPopper .SfxPopper-root .SfxMenu-root{
  overflow: scroll !important;
}

.FIE_text-font-name{
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 1);
  font-family: Roboto,Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 12px;
  height: 32px;
  border-radius: 4px;
  margin-left: 0px !important;
  position: relative !important;
  width: 190px !important;
  border: 1px solid var(--gray-200) !important;
}
.FIE_button-add-font{
  border: 1px solid var(--gray-200) !important;
  background: rgba(255, 255, 255, 1);
  font-family: Roboto,Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px;
  color:#666;
}
.Toastify__toast-container--top-right {
  top: 155px !important; /* Distance from the top */
}
/* end of spinner */
